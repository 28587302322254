import Vue from "vue";
import { getNumberAlias } from "@/utilities";
Vue.mixin({
  computed:{
      envVariables(){
        return process.env
      }
  },
  filters:{
    titleCase(value){
      if(!value) return ''

      let words = value.split(' ').map(i => {
        const w = i.trim()
        return w || w[0].toUpperCase() + w.substring(1)
      })

      return words

    },
    humanNumber(val){
      const number = parseInt(val)
      const text  = val ? val.toString().length : ""
      switch(text){
          case 1:
          case 2:
          case 3:
              return number
          case 4:
              return (number/1000).toFixed(1) + 'K'
          case 5:
              return (number/1000).toFixed(1) + 'K'
          case 6:
              return (number/1000).toFixed(1) + 'K'
          case 7:
              return (number/1000000).toFixed(1)+ 'M'
          case 8:
              return (number/1000000).toFixed(1) + 'M'
          case 9:
              return (number/1000000).toFixed(1) + 'M'
          case 10:
              return (number/1000000000).toFixed(1) + 'B'
          default:
            return 0
      }
    }
  },
  methods:{
    getAvatar(user) {
      if(!user){
        return
      }
      return user.avatar ? user.avatar.replace('block://', this.envVariables.VUE_APP_STORAGE_BUCKET) : `/img/custom/avatar-` + getNumberAlias(user.fname) + '.png'
      
    },
  }
  
})
