import {avatarColors} from "./constant"

const arrayFromObject = (obj) => {
  return Object.keys(obj).map((k) => obj[k]);
}
const getTimestamp = () => {
  return Math.round(new Date() / 1000);
}

const getColor = () => {
  return avatarColors[Math.round(Math.random()*14)+1]
}

 const getNumberAlias = (text) => {
  const len = text.length
  
  switch (true) {
    case (len%2 == 0):
      return 2

    case (len%3 == 0):
      return 3
  
    case (len%5 == 0):
      return 4
  
    default:
      return 1
  }

}

export {
  arrayFromObject,
  getNumberAlias,
  getTimestamp,
  getColor,
}